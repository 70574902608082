var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"text-start pa-6 py-8 ma-4 mt-0",attrs:{"rounded":"xl","width":_vm.$vuetify.breakpoint.mdAndUp ? '786px' : _vm.$vuetify.breakpoint.smAndUp ? '586px' : ''}},[_c('div',{staticClass:"d-flex justify-space-between mb-6"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("mdi-arrow-left")]),_c('div',{staticClass:"text-h6 ml-2"},[_vm._v("Pool detail")])],1),_c('div',{staticClass:"d-flex"},[(_vm.paused)?_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.onUnpauseState = !_vm.onUnpauseState}}},[_vm._v("mdi-play-circle-outline")]):_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.onPauseState = !_vm.onPauseState}}},[_vm._v("mdi-pause-circle-outline")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{staticClass:"text-left"},[_c('v-list-item',{attrs:{"disabled":_vm.closed}},[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.onCloseState = !_vm.onCloseState}}},[_vm._v(" Delete")])],1),_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.copyLink}},[_vm._v("Copy link to this list")])],1)],1)],1)],1)]),_c('v-sheet',{attrs:{"color":"primary secondary pa-6","rounded":"lg"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('HoverEditCardText',{attrs:{"sizeIcon":25},on:{"onEdit":function($event){_vm.editNameState = !_vm.editNameState}}},[_c('div',{staticClass:"d-flex"},[_c('v-img',{attrs:{"src":require("@/assets/icons/claim-icon.svg"),"max-width":"24px","contain":""}}),_c('div',{staticClass:"ml-2 text-medium font-weight-medium"},[_vm._v(" "+_vm._s(_vm.claimInfo.name)+" ")])],1)]),(_vm.isCompleted)?_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success ligten-2 "}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" Completed ")],1):_c('div',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-minus")]),_vm._v(" Remaining ("+_vm._s(_vm.claimedNumber)+"/"+_vm._s(_vm.winnerList.length)+") ")],1)],1),_c('HoverEditCardText',{staticClass:"text-caption mt-4",attrs:{"sizeIcon":18},on:{"onEdit":function($event){_vm.editDescriptionState = !_vm.editDescriptionState}}},[_vm._v(" "+_vm._s(_vm.claimInfo.description)+" ")]),_c('v-progress-linear',{staticClass:"mb-6 mt-4",attrs:{"background-color":"rgba(255, 255, 255, 0.06)","height":"2","color":"primary","value":(_vm.claimedNumber * 100) / (_vm.winnerList.length || 1)}}),_c('div',{class:{
        'd-flex justify-space-between': _vm.$vuetify.breakpoint.smAndUp,
        'd-flex flex-column': _vm.$vuetify.breakpoint.xs,
      }},[_c('div',{class:{
          'd-flex justify-space-between mb-2': _vm.$vuetify.breakpoint.xs,
          'd-flex flex-column': _vm.$vuetify.breakpoint.smAndUp,
        }},[_c('div',{staticClass:"text-caption grey--text mb-1"},[_vm._v("Create date")]),_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm._f("ddmmyyyy")(_vm.claimInfo.createdDate)))])]),_c('div',{class:{
          'd-flex justify-space-between mb-2': _vm.$vuetify.breakpoint.xs,
          'd-flex flex-column': _vm.$vuetify.breakpoint.smAndUp,
        }},[_c('HoverEditCardText',{staticClass:"text-caption grey--text",attrs:{"mlIcon":0,"disableState":!_vm.beforeActiveDate},on:{"onEdit":function($event){_vm.editActiveDateState = !_vm.editActiveDateState}}},[_c('div',[_vm._v(" Claiming day "),_c('div',{staticClass:"text-body-1 mt-1 white--text"},[_vm._v(_vm._s(_vm._f("ddmmyyyy")(_vm.claimInfo.activeDate)))])])])],1),_c('div',{class:{
          'd-flex justify-space-between mb-2': _vm.$vuetify.breakpoint.xs,
          'd-flex flex-column': _vm.$vuetify.breakpoint.smAndUp,
        }},[_c('div',{staticClass:"text-caption grey--text mb-1"},[_vm._v("Total amount")]),_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.claimInfo.amount))])]),_c('div',{class:{
          'd-flex justify-space-between mb-2': _vm.$vuetify.breakpoint.xs,
          'd-flex flex-column': _vm.$vuetify.breakpoint.smAndUp,
        }},[_c('div',{staticClass:"text-caption grey--text mb-1"},[_vm._v("Address")]),_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.winnerList.length))])])])],1),(_vm.beforeActiveDate && _vm.winnerList.length === 0)?_c('div',[_c('div',{staticClass:"text-medium my-6"},[_vm._v("Claim list")]),_c('v-sheet',{staticStyle:{"background":"transparent !important","border":"1px solid grey !important"},attrs:{"color":"primary secondary pa-6","rounded":"xl"}},[_c('div',{staticClass:"d-flex flex-column align-center text-center mb-16 mt-6"},[_c('div',{staticClass:"font-weight-bold text-h5 my-6"},[_vm._v("No wallet address yet!")]),_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":function($event){return _vm.$router.push((_vm.id + "/addWallet/step=1"))}}},[_vm._v(" Add Wallet address")])],1)])],1):_c('div',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"md":"4","cols":"12"}},[_c('div',{staticClass:"text-medium my-6"},[_vm._v("Claim list")])]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"md":"8","cols":"12"}},[_c('v-text-field',{attrs:{"placeholder":"Address wallet","prepend-inner-icon":"mdi-magnify","dense":"","outlined":"","rounded":"","hide-details":""},on:{"input":_vm.searchInputChange}}),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.beforeActiveDate},on:{"click":function($event){_vm.addWalletState = !_vm.addWalletState}}},[_vm._v(" Add wallet address ")])],1),_c('v-col',[_c('v-data-table',{attrs:{"items":_vm.winnerInfo,"items-key":"id","headers":_vm.originalHeaders,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.stt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.index))]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.amount,8, 1))+" ")]}},{key:"item.edit",fn:function(ref){
        var item = ref.item;
return [_c('ClaimWalletListActions',{attrs:{"disableState":!_vm.beforeActiveDate,"winner":{
                amount: item.amount,
                address: item.address,
                claimId: _vm.id,
                decimals: _vm.token ? _vm.token.decimals : null,
              }},on:{"updateWalletDone":function($event){return _vm.updateWalletDone({ $event: $event, index: item.index })}}})]}}],null,true)})],1)],1)],1),_c('ClaimAddWalletDialog',{attrs:{"state":_vm.addWalletState,"claim":{ id: this.id, decimals: this.token ? this.token.decimals : 18 }},on:{"addWalletDone":_vm.addWalletDone}}),_c('ClaimEditDescriptionDialog',{attrs:{"state":_vm.editDescriptionState,"claim":{ id: this.id, description: this.description, name: this.name }},on:{"updateDone":_vm.editDescriptionDone}}),_c('ClaimEditNameDialog',{attrs:{"state":_vm.editNameState,"claim":{ id: this.id, name: this.name }},on:{"updateDone":_vm.editNameDone}}),_c('ClaimEditActiveDateDialog',{attrs:{"state":_vm.editActiveDateState,"claim":{ id: this.id, name: this.name, activeTime: this.activeDate }},on:{"updateDone":_vm.editActiveDateDone}}),_c('ClaimPauseDialog',{attrs:{"state":_vm.onPauseState,"claim":{ id: this.id }},on:{"updateDone":_vm.updatePauseState}}),_c('ClaimUnpauseDialog',{attrs:{"state":_vm.onUnpauseState,"claim":{ id: this.id }},on:{"updateDone":_vm.updatePauseState}}),_c('ClaimCloseDialog',{attrs:{"state":_vm.onCloseState,"claim":{ id: this.id }},on:{"updateDone":_vm.updateCloseState}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }