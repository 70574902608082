<template>
  <v-sheet
    rounded="xl"
    class="text-start pa-6 py-8 ma-4 mt-0"
    :width="$vuetify.breakpoint.mdAndUp ? '786px' : $vuetify.breakpoint.smAndUp ? '586px' : ''"
  >
    <div class="d-flex justify-space-between mb-6">
      <div class="d-flex align-center">
        <v-icon @click="$router.go(-1)">mdi-arrow-left</v-icon>
        <div class="text-h6 ml-2">Pool detail</div>
      </div>

      <div class="d-flex">
        <v-icon color="primary" v-if="paused" @click="onUnpauseState = !onUnpauseState">mdi-play-circle-outline</v-icon>
        <v-icon color="primary" @click="onPauseState = !onPauseState" v-else>mdi-pause-circle-outline</v-icon>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list class="text-left">
            <v-list-item :disabled="closed">
              <v-list-item-title style="cursor: pointer" @click="onCloseState = !onCloseState">
                Delete</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title style="cursor: pointer" @click="copyLink">Copy link to this list</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-sheet color="primary secondary pa-6" rounded="lg">
      <div class="d-flex justify-space-between">
        <HoverEditCardText :sizeIcon="25" @onEdit="editNameState = !editNameState">
          <div class="d-flex">
            <v-img :src="require(`@/assets/icons/claim-icon.svg`)" max-width="24px" contain />
            <div class="ml-2 text-medium font-weight-medium">
              {{ claimInfo.name }}
            </div>
          </div>
        </HoverEditCardText>

        <div v-if="isCompleted">
          <v-icon class="mr-2" color="success ligten-2 ">mdi-check-circle-outline</v-icon> Completed
        </div>
        <div v-else>
          <v-icon class="mr-2">mdi-minus</v-icon>
          Remaining ({{ claimedNumber }}/{{ winnerList.length }})
        </div>
      </div>
      <HoverEditCardText
        :sizeIcon="18"
        class="text-caption mt-4"
        @onEdit="editDescriptionState = !editDescriptionState"
      >
        {{ claimInfo.description }}
      </HoverEditCardText>
      <v-progress-linear
        background-color="rgba(255, 255, 255, 0.06)"
        height="2"
        color="primary"
        class="mb-6 mt-4"
        :value="(claimedNumber * 100) / (winnerList.length || 1)"
      />
      <div
        :class="{
          'd-flex justify-space-between': $vuetify.breakpoint.smAndUp,
          'd-flex flex-column': $vuetify.breakpoint.xs,
        }"
      >
        <div
          :class="{
            'd-flex justify-space-between mb-2': $vuetify.breakpoint.xs,
            'd-flex flex-column': $vuetify.breakpoint.smAndUp,
          }"
        >
          <div class="text-caption grey--text mb-1">Create date</div>
          <div class="text-body-1">{{ claimInfo.createdDate | ddmmyyyy }}</div>
        </div>
        <div
          :class="{
            'd-flex justify-space-between mb-2': $vuetify.breakpoint.xs,
            'd-flex flex-column': $vuetify.breakpoint.smAndUp,
          }"
        >
          <HoverEditCardText
            class="text-caption grey--text"
            :mlIcon="0"
            @onEdit="editActiveDateState = !editActiveDateState"
            :disableState="!beforeActiveDate"
          >
            <div>
              Claiming day
              <div class="text-body-1 mt-1 white--text">{{ claimInfo.activeDate | ddmmyyyy }}</div>
            </div>
          </HoverEditCardText>
        </div>
        <div
          :class="{
            'd-flex justify-space-between mb-2': $vuetify.breakpoint.xs,
            'd-flex flex-column': $vuetify.breakpoint.smAndUp,
          }"
        >
          <div class="text-caption grey--text mb-1">Total amount</div>
          <div class="text-body-1">{{ claimInfo.amount }}</div>
        </div>
        <div
          :class="{
            'd-flex justify-space-between mb-2': $vuetify.breakpoint.xs,
            'd-flex flex-column': $vuetify.breakpoint.smAndUp,
          }"
        >
          <div class="text-caption grey--text mb-1">Address</div>
          <div class="text-body-1">{{ winnerList.length }}</div>
        </div>
      </div>
    </v-sheet>
    <div v-if="beforeActiveDate && winnerList.length === 0">
      <div class="text-medium my-6">Claim list</div>
      <v-sheet
        color="primary secondary pa-6"
        rounded="xl"
        style="background: transparent !important; border: 1px solid grey !important"
      >
        <div class="d-flex flex-column align-center text-center mb-16 mt-6">
          <div class="font-weight-bold text-h5 my-6">No wallet address yet!</div>
          <v-btn rounded color="primary" @click="$router.push(`${id}/addWallet/step=1`)"> Add Wallet address</v-btn>
        </div>
      </v-sheet>
    </div>
    <div v-else>
      <v-row>
        <v-col md="4" cols="12" class="d-flex align-center">
          <div class="text-medium my-6">Claim list</div>
        </v-col>
        <v-col md="8" cols="12" class="d-flex align-center">
          <v-text-field
            placeholder="Address wallet"
            prepend-inner-icon="mdi-magnify"
            dense
            outlined
            rounded
            hide-details
            @input="searchInputChange"
          >
          </v-text-field>
          <v-btn text color="primary" @click="addWalletState = !addWalletState" :disabled="!beforeActiveDate">
            Add wallet address
          </v-btn>
        </v-col>
        <v-col>
          <v-data-table :items="winnerInfo" items-key="id" :headers="originalHeaders" :loading="loading">
            <template v-slot:[`item.stt`]="{ item }"> {{ item.index }}</template>
            <template v-slot:[`item.amount`]="{ item }">
              {{ item.amount | formatNumber(8, 1) }}
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <ClaimWalletListActions
                :disableState="!beforeActiveDate"
                :winner="{
                  amount: item.amount,
                  address: item.address,
                  claimId: id,
                  decimals: token ? token.decimals : null,
                }"
                @updateWalletDone="updateWalletDone({ $event, index: item.index })"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <ClaimAddWalletDialog
      :state="addWalletState"
      :claim="{ id: this.id, decimals: this.token ? this.token.decimals : 18 }"
      @addWalletDone="addWalletDone"
    />
    <ClaimEditDescriptionDialog
      :state="editDescriptionState"
      :claim="{ id: this.id, description: this.description, name: this.name }"
      @updateDone="editDescriptionDone"
    />
    <ClaimEditNameDialog :state="editNameState" :claim="{ id: this.id, name: this.name }" @updateDone="editNameDone" />
    <ClaimEditActiveDateDialog
      :state="editActiveDateState"
      :claim="{ id: this.id, name: this.name, activeTime: this.activeDate }"
      @updateDone="editActiveDateDone"
    />
    <ClaimPauseDialog :state="onPauseState" :claim="{ id: this.id }" @updateDone="updatePauseState" />
    <ClaimUnpauseDialog :state="onUnpauseState" :claim="{ id: this.id }" @updateDone="updatePauseState" />
    <ClaimCloseDialog :state="onCloseState" :claim="{ id: this.id }" @updateDone="updateCloseState" />
  </v-sheet>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
const originalHeaders = [
  {
    text: '-',
    align: 'left',
    sortable: false,
    value: 'stt',
  },
  {
    text: 'Wallet address',
    align: 'left',
    sortable: false,
    value: 'address',
  },
  {
    text: 'amount',
    align: 'right',
    sortable: false,
    value: 'amount',
  },
  {
    text: 'Edit',
    align: 'right',
    sortable: false,
    value: 'edit',
  },
]
export default {
  components: {
    HoverEditCardText: () => import('@/components/base/HoverEditCardText.vue'),
    ClaimWalletListActions: () => import('@/modules/claimer/ClaimWalletListActions.vue'),
    ClaimAddWalletDialog: () => import('@/components/dialogs/claimer/ClaimAddWalletDialog.vue'),
    ClaimEditDescriptionDialog: () => import('@/components/dialogs/claimer/ClaimEditDescriptionDialog.vue'),
    ClaimEditNameDialog: () => import('@/components/dialogs/claimer/ClaimEditNameDialog.vue'),
    ClaimEditActiveDateDialog: () => import('@/components/dialogs/claimer/ClaimEditActiveDateDialog.vue'),
    ClaimPauseDialog: () => import('@/components/dialogs/claimer/ClaimPauseDialog.vue'),
    ClaimUnpauseDialog: () => import('@/components/dialogs/claimer/ClaimUnpauseDialog.vue'),
    ClaimCloseDialog: () => import('@/components/dialogs/claimer/ClaimCloseDialog.vue'),
  },
  data() {
    return {
      id: '',
      editName: false,
      editDesciption: false,
      originalHeaders: originalHeaders,
      addWalletState: false,
      editDescriptionState: false,
      editNameState: false,
      editActiveDateState: false,
      onPauseState: false,
      onUnpauseState: false,
      onCloseState: false,
    }
  },
  computed: {
    ...mapState('auth', ['multiClaimHandler', 'account']),
    ...mapState('claimDetailByOwner', [
      'winnerList',
      'amountList',
      'name',
      'activeDate',
      'description',
      'amount',
      'token',
      'paused',
      'closed',
      'loading',
    ]),
    ...mapGetters('claimDetailByOwner', [
      'claimInfo',
      'isCompleted',
      'beforeActiveDate',
      'winnerInfo',
      'claimedNumber',
    ]),
  },
  methods: {
    ...mapActions('claimDetailByOwner', ['fetchClaimDetail', 'searchInputChange']),
    updateWalletDone(event) {
      this.$store.commit('claimDetailByOwner/updateAmountList', { amount: event.$event, index: event.index })
    },
    addWalletDone(event) {
      this.$store.commit('claimDetailByOwner/addWallet', { amount: event.amount, address: event.address })
    },
    editDescriptionDone(event) {
      this.$store.commit('claimDetailByOwner/changeState', { description: event })
    },
    editNameDone(event) {
      this.$store.commit('claimDetailByOwner/changeState', { name: event })
    },
    editActiveDateDone(event) {
      this.$store.commit('claimDetailByOwner/changeState', { activeDate: event })
    },
    updatePauseState(event) {
      this.$store.commit('claimDetailByOwner/changeState', { paused: event })
    },
    updateCloseState(event) {
      this.$store.commit('claimDetailByOwner/changeState', { closed: event })
      this.$router.push('/claimer/pools')
    },
    copyLink() {
      navigator.clipboard.writeText(`${process.env.VUE_APP_END_POINT}/claimer/user/${this.id}`)
    },
  },
  async mounted() {
    this.id = this.$route.params.id
    await this.fetchClaimDetail({
      multiClaimHandler: this.multiClaimHandler,
      id: this.id,
      router: this.$router,
      account: this.account,
    })
    if (this.token && this.token.symbol) {
      this.originalHeaders[2].text = `Amount (${this.token.symbol})`
    }
  },
}
</script>
<style lang="scss">
tbody {
  tr {
    background-color: transparent !important;
  }
  tr:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.01);
  }
  cursor: pointer;
}
</style>
